/* 
Colors 
text #FFFFFF;
background grey #C7CECA;
background brick #9C4636;
background cards #b5bdb8;
Font
"Raleway", sans-serif;
font-size: 17px;
font-weight: 400;
*/

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  font-size: 17px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #FFFFFF;
  font-family: "Raleway", sans-serif;
}

p {
  font-weight: 400;
  font-size: 0.8em;
  margin-bottom: 0.75rem; 
}

h1 {
  font-size: 1em;
  font-weight: 600;
}

.root-container {
  height:100vh;
  width:100%;
}

.content-row {
  height: 100vh;
  width:100%;
}

.home-container,
.contact-container,
.about-container,
.not-found-container,
.work-container,
.work-nav-container 
{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #C7CECA;
  padding-bottom: 50px;
}

.background-grey {
  background-color: #C7CECA
}

.background-brown {
  background-color: #9C4636;
}

/* --- NavBar --- */
.nav-hamburger {
  border: none;
}

#responsive-navbar-nav {
  text-align: right;
  background-color: transparent;
  margin: 10px 10px;
}

#nav-container {
  position: fixed;
  top: 0;
}

.nav-links
{
  /* can only style with !important */
  color: #FFFFFF !important;
  font-weight: 700;
  font-size: 0.75em; 
}

.project-btn {
  border-radius: 3px;
  background-color: #C7CECA;
  font-weight: 800;
  font-size: 0.75em;
  color: #FFFFFF;
  display: inline-block;
  float: left;
  padding: 5px 50px;
  margin: 5px 0 15px;
}

.nav-container {
  margin-right: 10px;
}

a.nav-links:active {
 color: rgba(225, 225, 225, 0) !important;
}

.nav-logo {
  margin-left: 15px;
  width: 30%;
  height: 30%;
}


/* --- About  & Contact --- */
.about-text {
  margin: 20vh 50px 60px;
  /* padding: 60px; */
  flex-direction: column;
  justify-content: center;
}

.my-skills {
  display: inline;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 0.75em;
}


.my-skills-list {
  margin-top: 1.25rem;
}

.contact-text
{
  margin: 30vh 50px ;
  flex-direction: column;
  justify-content: center;
}


.contact-list,
.my-tag-list,
.my-skills-list
{
  list-style: none;
  line-height: 1.25rem;
  margin-bottom: 4vh;
}

a.contact-me {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 0.9em;
  text-decoration: none;
  line-height: 1.5rem;
}

a.contact-me:active {
  color: rgba(225, 225, 225, 0) !important;
 }

/* --- Work --- */
.my-card,
.my-design-card 
{
  background-color: #b5bdb8;
  border-radius: 0%;
  border: none;
  margin: 80px 0px 0px;
}

.my-tags {
  display: inline;
  color: #FFFFFF;
  background-color: transparent;
  font-weight: 700;
  font-size: 0.7em;
  border: none;
}

#my-card-container {
  margin-top: -2vh;
}

.my-row {
  margin-top: 80px;
}

/* --- WorkNav --- */
 a.work-link {
  color: #FFFFFF;
  font-weight: 700;
  font-size: 0.85em;
}

a.work-link:active{
  color: none;
  text-decoration: none;
  }

/* --- Home --- */
.blob-container {
  display: flex;
  position: relative;
}

.blob {
  border-radius: 78% 25% 37% 67% / 59% 49% 51% 41%;
  height: 160px;
  width: 160px;
   /* height: 40vw;
  width: 40vw; */
  background-color: #9C4636; 
  position: absolute;
  top: -50px;
  left: 10px;
  animation: move 13s linear infinite;
  transform-origin: 20% 10%;
  z-index: 0;
}

@keyframes move {
  0%   { transform: scale(1)  translate(0px, 0px); }
  20%  { transform: scale(0.9) translate(45px, 20px) rotate(90deg); }
  40%  { transform: scale(0.8) translate(110px, 70px) rotate(120deg); }
  60%  { transform: scale(0.7) translate(70px, -100px) rotate(20deg); }
  80%  { transform: scale(0.9)translate(10px, 80px) rotate(20deg); }
  100% { transform: scale(1) translate(0px, 0px); } 
} 

.intro-text-1 {
  font-weight: 800;
  color: #C7CECA;
  z-index: 1;
}

.intro-text-2 {
  font-weight: 600;
}

.not-found-text {
  font-weight: 700;
  font-size: 1.7rem;
}
.nav-logo {
  width: 50px;
}


/* --------- Tablet --------*/
@media (min-width: 768px) {
  #nav-container {
    border-bottom: 1.9px solid #FFFFFF;
    margin: 0px 35px;
    padding-right: 5px;
    padding-left: 5px;
  }

  .about-text {
    margin-top: 12vh;
  }

  .nav-logo {
    width: 80px;
  } 
  /* .nav-logo {
    width: 150px;
  } */
  #responsive-navbar-nav {
    margin-right: 0px;
  }
  .home-text {
    margin-top: 400px;
  }
  .work-coding  {
    padding-top: 100px ;
    margin-top: 200px;
  }
  .my-card {
    margin-right: 15px; 
    margin-left: 15px;
  }
  .my-img {
    padding: 0 15px;
  }
  .my-row {
    margin-top: 50px;
  }
  .my-card-row {
    margin-top: -14vh;
  }
  .nav-links {
    font-size: 1em; 
  }
  p,
  .intro-text-1,
  .intro-text-2,
  .card-text,
  .my-tags
  {
    font-size: 1em;
    margin-bottom: 1rem;  
  }
  h1 {
    font-size: 1.4em;
    margin-bottom: 0.7rem; 
  }
  .project-btn {
    font-size: 1em; 
    margin: -5px 0 30px;
    border-radius: 4px;
    padding: 0.3rem 4rem;
  }
  .card-title {
    font-size: 1.5em; 
    margin-bottom: 0.25em;
  }
  .my-skills {
    font-size: 1rem; 
  }
  .my-skills-list,
  .my-tag-list {
    line-height: 1.8rem;
  }
  a.contact-me {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .blob {
    height: 25vw;
    width: 25vw;
  }
}


/* --------- Desktop ---------*/
@media (min-width:1280px) {
 .home-container {
   margin-left: 3vw;
 }
 p,
 .intro-text-1,
 .intro-text-2,
 .card-text,
 .my-tags
 {
   font-size: 1.25em; 
   margin-bottom: 1.25rem;  
 }
 h1 {
  font-size: 1.5em;
  margin-bottom: 1rem; 
}
p {
  margin-bottom: 1rem; 
}
 .blob {
  height: 10vw;
  width: 10vw;
  animation: move 14s linear infinite;
}
@keyframes move {
  0%   { transform: scale(1)  translate(0vw, 0vw); }
  20%  { transform: scale(0.9) translate(-2vw, -3vw) rotate(90deg); }
  40%  { transform: scale(0.8) translate(4vw, 4vw) rotate(120deg)  ; }
  60%  { transform: scale(0.7) translate(9vw, -3vw) rotate(20deg); }
  80%  { transform: scale(0.9)translate(-1vw, 6vw) rotate(20deg); }
  100% { transform: scale(1) translate(0vw, 0vw); } 
} 
.blob-container {
  margin-right: -8vw;
}
.nav-logo {
  width: 90px;
  margin-left: 0.75em;
}
.nav-links {
  font-size: 1.2em;
  margin-left: 3em;
}
#nav-container {
  border-bottom: 1.9px solid #FFFFFF;
  margin: 1.5vh 3vw; 
  padding-bottom: 10px;
}
a.work-link,
.not-found-text
{
  font-size: 2.5rem; 
}
.my-work-row {
  margin-left: -13vw;
  flex-direction: column;
}
.work-link {
  line-height: 1.5rem;
  padding: 0 13vw; 
}
.work-link:hover {
  text-decoration: none;
}
.work-link:active {
color: none;
}
.about-text  {
  margin-left: 7.5vw;
}
.contact-text {
  margin-left: 6vw;
}
a.contact-me {
  font-size: 1.15rem;
  line-height: rem;
}
.my-skills {
  font-size: 1.15rem; 
}
.my-skills-list {
  margin-top: 1.8rem;
}
.my-skills-list,
.my-tag-list {
  line-height: 1.6rem;
  margin-bottom: 4rem;
}
#my-card-container {
  max-width: 70vw;
  margin: 5vh 15vw 5vh;
}
.card-body {
  padding: 5rem 15rem 3rem 3rem;
}
.card-title {
  font-size: 2em; 
  margin-bottom: 1vh;
}
.project-btn {
  font-size: 1.3em; 
  margin: -10px 0 30px;
  border-radius: 5px;
  padding: 0.35rem 4rem;
}
.my-row {
  margin-top: 5vh;
}
#my-card-container {
  padding-top: 7vh;
}
.about-container,
.not-found-container
{
  justify-content: flex-end;
  padding-bottom: 10vh;
}
.contact-container {
  padding-top: 10vh;
}
.home-container {
  justify-content: flex-end;
  padding-bottom: 20vh;
}
.work-nav-container,
.not-found-container {
  justify-content: center;
  padding-bottom: 0;
  padding-top: 5vw;
}
}


